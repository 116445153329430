import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src2870615501/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "bundle-getting-started"
    }}>{`Bundle Getting Started`}</h1>
    <p>{`If you would like to get started building your own bundle or customizing you will need to first check out your bundle project. If you're just
getting started you'll want to check out the `}<a parentName="p" {...{
        "href": "https://github.com/kineticdata/customer-project-default"
      }}>{`Customer Default Project`}</a>{` source code.`}</p>
    <h2 {...{
      "id": "requirements"
    }}>{`Requirements`}</h2>
    <p>{`In addition you will need the following:`}</p>
    <ul>
      <li parentName="ul">{`Node.js (v18.x recommended)`}</li>
      <li parentName="ul">{`Yarn (1.22.x)`}</li>
      <li parentName="ul">{`Knowledge of React and JavaScript`}</li>
      <li parentName="ul">{`Bundle Project Source (e.g. the `}<a parentName="li" {...{
          "href": "https://github.com/kineticdata/customer-project-default"
        }}>{`Customer Default Project`}</a>{`)`}</li>
      <li parentName="ul">{`Development OAuth Client`}</li>
      <li parentName="ul">{`Trusted Domain changes`}</li>
    </ul>
    <h3 {...{
      "id": "development-oauth-client"
    }}>{`Development OAuth Client`}</h3>
    <p>{`In order for your bundle to authenticate correctly with your Kinetic Platform you will need to create a valid OAuth Client
for it to use. This is how your environment knows to send authentication credentials back to your development server. To
set this up you will need to navigate to the OAuth Security tab (Kinetic Management Console > Space > Settings > OAuth Tab):`}</p>
    <pre><code parentName="pre" {...{}}>{`Name          ->  Kinetic Bundle - Dev
Description   ->  oAuth Client for client-side bundles in development mode
Client ID     ->  kinetic-bundle-dev
Client Secret ->  <any value>
Redirect URI  ->  http://localhost:3000/app/oauth/callback
`}</code></pre>
    <h3 {...{
      "id": "trusted-domains"
    }}>{`Trusted Domains`}</h3>
    <p>{`The server must also have trusted domains configured in order for your local development server to be allowed to contact
your Kinetic Platform. These settings allow the local development server's bundle code to access the platform's API and
to embed frames (which is needed for authentication). Navigate to Space Security Tab (Kinetic Management Console > Space > Settings > Security Tab):`}</p>
    <pre><code parentName="pre" {...{}}>{`Trusted Resource Domain   ->    http://localhost:3000
Trusted Frame Domain      ->    localhost:3000
`}</code></pre>
    <h2 {...{
      "id": "quick-start"
    }}>{`Quick Start`}</h2>
    <p>{`From a command line open inside the `}<inlineCode parentName="p">{`bundle`}</inlineCode>{` directory, run the below commands to start a development server.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ yarn install
$ yarn start
`}</code></pre>
    <p>{`Upon `}<inlineCode parentName="p">{`start`}</inlineCode>{`, you will be prompted to enter a Kinetic Request CE server to connect to.`}</p>
    <p>{`Once the development server starts up, you can access it at `}<a parentName="p" {...{
        "href": "http://localhost:3000"
      }}>{`http://localhost:3000`}</a>{`. Any changes you make to your local code will then automatically cause the server to reload with your new changes.`}</p>
    <p><strong parentName="p">{`For detailed bundle development instructions, please see the `}<a parentName="strong" {...{
          "href": "https://github.com/kineticdata/customer-project-default/blob/master/bundle/README.md"
        }}>{`Bundle README`}</a>{`.`}</strong></p>
    <h2 {...{
      "id": "starting-a-custom-bundle"
    }}>{`Starting a Custom Bundle`}</h2>
    <p><strong parentName="p">{`Requirements:`}</strong>{` GitHub account and knowledge of git.`}</p>
    <p>{`In order to customize or build your own custom bundle, it is recommended to begin with this project. You can either `}<a parentName="p" {...{
        "href": "https://docs.github.com/en/github/getting-started-with-github/fork-a-repo"
      }}>{`fork`}</a>{` this repository, or create your own new repository and configure this repository as its upstream, by following the instructions below.`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.github.com/en/github/getting-started-with-github/create-a-repo"
        }}>{`Create a Github repository.`}</a></li>
    </ol>
    <p><strong parentName="p">{`Do NOT check the 'Initialize this repository with a README' checkbox.`}</strong>{` It must remain unchecked so that we can merge in the history from this repository to allow future syncing.`}</p>
    <p>{`Let's use the repository name `}<inlineCode parentName="p">{`awesome-custom-bundle`}</inlineCode>{` in the below examples.`}</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.github.com/en/github/using-git/getting-changes-from-a-remote-repository#cloning-a-repository"
        }}>{`Clone your repository to your local machine`}</a>{` and change to the newly cloned repository.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ git clone https://github.com/<USERNAME>/awesome-custom-bundle.git
> Cloning into 'awesome-custom-bundle'...
$ cd awesome-custom-bundle
`}</code></pre>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Set this repository as the upstream in your `}<inlineCode parentName="li">{`awesome-custom-bundle`}</inlineCode>{` repository. We'll also update the push upstream url to an invalid value to prevent accidental pushes to the upstream, in case you happen to have access to do so. Finally, running `}<inlineCode parentName="li">{`git remote -v`}</inlineCode>{` will show us the configured remotes.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ git remote add upstream https://github.com/kineticdata/customer-project-default.git --no-tags
$ git remote set-url --push upstream no_push
$ git remote -v
> origin      https://github.com/<USERNAME>/awesome-custom-bundle.git (fetch)
> origin      https://github.com/<USERNAME>/awesome-custom-bundle.git (push)
> upstream    https://github.com/kineticdata/customer-project-default.git (fetch)
> upstream    no_push (push)
`}</code></pre>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Fetch the upstream branches. Then create a branch in your repo by checking out the upstream branch. This will bring over all the code and history from the upstream, allowing you to merge in future changes from the upstream to your local repo. The `}<inlineCode parentName="li">{`--no-track`}</inlineCode>{` flag will make sure this branch does not track the upstream, as we'll want it to track origin since we'll be making changes in it. Lastly, we'll push the our master branch to the origin remote.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ git fetch upstream
> From https://github.com/kineticdata/customer-project-default
>  * [new branch]      develop    -> upstream/develop
>  * [new branch]      master     -> upstream/master
$ git checkout -b master upstream/master --no-track
$ git push -u origin master
> To https://github.com/<USERNAME>/awesome-custom-bundle.git
>  * [new branch]      master     -> master
`}</code></pre>
    <p>{`You are now ready to start developing in your custom bundle repository. See the `}<a parentName="p" {...{
        "href": "bundle/README.md"
      }}>{`Bundle README`}</a>{` for detailed bundle development instructions.`}</p>
    <p>{`In order to pull the latest changes from the upstream `}<inlineCode parentName="p">{`customer-project-default`}</inlineCode>{` repository into your custom `}<inlineCode parentName="p">{`awesome-custom-bundle`}</inlineCode>{` repository, follow the below instructions. You will need to manually resolve any conflicts during the merge.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ git checkout master
$ git fetch upstream
$ git merge upstream/master
$ git push origin master
`}</code></pre>
    <h6 {...{
      "id": "notes"
    }}>{`Notes`}</h6>
    <ul>
      <li parentName="ul">{`You can use either the master branch (latest stable release) or the develop branch (latest unreleased changes) from the upstream. It is not recommended to use other upstream branches if they exist.`}</li>
      <li parentName="ul">{`You can name your local/origin branch whatever you'd like; master was just used in the above examples.`}</li>
      <li parentName="ul">{`Once you start customizing your bundle, it will be your responsibility to merge in and resolve any conflicts from the upstream when we make changes to `}<inlineCode parentName="li">{`customer-project-default`}</inlineCode>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "folder-structure"
    }}>{`Folder Structure`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`  .
  ├─ artifacts               # Contains artifacts generated for the project
  ├─ bundle                  # Contains the bundle React code
  │  ├─ packages             # Individual packages that define different parts of the bundle
  │  │  ├─ app               # Entry point to the bundle application
  │  │  ├─ components        # Package that allows for overriding common components
  │  │  └─ ...               # Other custom packages may be added
  │  └─ package.json         # Monorepo configuration file
  └─ plugins                 # Contains custom plugins for the project
`}</code></pre>
    <ul>
      <li parentName="ul"><strong parentName="li">{`artifacts`}</strong></li>
    </ul>
    <p>{`Directory containing artifacts for the project, such as space exports generated from the SDK, or special documentation such as ADRs.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`bundle`}</strong></p>
        <p parentName="li">{`Directory containing all of the bundle code. See the `}<a parentName="p" {...{
            "href": "bundle/README.md"
          }}>{`Bundle README`}</a>{` for detailed bundle development instructions.`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`packages`}</strong></p>
            <p parentName="li">{`The bundle consists of multiple packages, where each package defines one area of functionality (e.g. service portal package).`}</p>
            <ul parentName="li">
              <li parentName="ul"><strong parentName="li">{`app`}</strong></li>
            </ul>
            <p parentName="li">{`The app package is the entry point to the entire bundle, and defines the rendering and authentication wrappers, as well as a default content renderer. This package is rendered when the bundle is loaded, and then in turn renders the content of the other packages as needed.`}</p>
            <ul parentName="li">
              <li parentName="ul"><strong parentName="li">{`components`}</strong></li>
            </ul>
            <p parentName="li">{`The components package exists to allow for overriding of common layout components used by other packages. `}<em parentName="p">{`This package is currently a work in progress.`}</em></p>
            <ul parentName="li">
              <li parentName="ul"><strong parentName="li"><em parentName="strong">{`other packages`}</em></strong></li>
            </ul>
            <p parentName="li">{`The majority of the prebuilt Kinetic packages are deployed to NPM, and are installed into the App package from NPM, so their code isn't part of this bundle.
New packages may be added and connected into the App package to provide custom functionality.`}</p>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <h4 parentName="li" {...{
          "id": "plugins"
        }}>{`plugins`}</h4>
      </li>
    </ul>
    <p>{`Directory containing all of the custom plugins (such as handlers, bridge adapters, etc.) created for the project.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      